// extracted by mini-css-extract-plugin
export var container = "toggle-module--container--nph9n";
export var switchContainer = "toggle-module--switchContainer--krAmD";
export var innerSwitch = "toggle-module--innerSwitch--P4e8c";
export var innerRight = "toggle-module--innerRight--aCo-K";
export var icon = "toggle-module--icon--hrdaH";
export var yellow = "toggle-module--yellow--kycD9";
export var moonWhite = "toggle-module--moonWhite--pTYAJ";
export var animateRight = "toggle-module--animateRight--X+G4r";
export var slideRight = "toggle-module--slideRight--M+CLb";
export var animateLeft = "toggle-module--animateLeft--JgHi6";
export var slideLeft = "toggle-module--slideLeft---AKUj";