// extracted by mini-css-extract-plugin
export var container = "header-module--container--i1m8L";
export var innerContainer = "header-module--innerContainer--t9mU-";
export var logoContainer = "header-module--logoContainer--rpQf1";
export var linkContainer = "header-module--linkContainer--s7aw4";
export var logoMain = "header-module--logoMain--dRMc4";
export var logoSecondary = "header-module--logoSecondary--6TE5h";
export var logoLink = "header-module--logoLink--10NcL";
export var navLink = "header-module--navLink--5U0hq";
export var navText = "header-module--navText--MwP+W";
export var githubIcon = "header-module--githubIcon--OQSDx";