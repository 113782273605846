// extracted by mini-css-extract-plugin
export var rootContainer = "hero-module--rootContainer--kH7hw";
export var main = "hero-module--main--x0pJ4";
export var widthContainer = "hero-module--widthContainer--PihKX";
export var root = "hero-module--root--cHntd";
export var heroTextContainer = "hero-module--heroTextContainer--vvW76";
export var heroContactContainer = "hero-module--heroContactContainer--GbYKp";
export var heroText = "hero-module--heroText--4H24d";
export var connectText = "hero-module--connectText--622zo";
export var heroPara = "hero-module--heroPara--hIvAK";
export var asideText = "hero-module--asideText--KigxZ";
export var iconsContainer = "hero-module--iconsContainer--rEDo8";
export var icon = "hero-module--icon--OW7On";
export var noselect = "hero-module--noselect--mZk5I";